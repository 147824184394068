<template>
  <div>
    <div class="header-component">
      <TrendingSearch />
    </div>
    <div class="main-container">
      <div class="left">
        <TrendingBuzzFilters />
      </div>
      <div :class="status ? 'mid' : 'collapse-mid'">
        <CustomTabsVue />
      </div>
      <div :class="status ? 'right' : 'collapse-right'">
        <div v-if="!status">
          <AttributeList @sidebarExpand="collapseSidebar" />
        </div>
        <div v-else class="horizontal-text">
          <div @click="collapseSidebar()">
            <span><img src="../../../assets/collapse-right-arrow.svg" /></span>
            <p>Featured Topics</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TrendingBuzzFilters from '@/components/LocalFilters/TrendingBuzzFilters.vue'
import TrendingSearch from '@/components/LocalFilters/TrendingSearch.vue'
import CustomTabsVue from '@/components/WordCloud/CustomTabs.vue'
import AttributeList from '@/components/WordCloud/AttributeList.vue'
import { metaTitle } from '@/constant/data'
import { mapActions } from 'vuex'

/** This is the parent component/view for the **Comments** page.
 * @displayName Comments
 */
export default {
  name: 'TrendingBuzz',
  components: {
    CustomTabsVue,
    TrendingSearch,
    TrendingBuzzFilters,
    AttributeList
  },
  data() {
    return {
      status: true
    }
  },

  metaInfo() {
    return {
      title: metaTitle.trendingBuzz
    }
  },
  mounted() {
    const localAttrList = JSON.parse(
      localStorage.getItem('sortedAttributeList')
    )
    const localSelecterAttrs = JSON.parse(
      localStorage.getItem('selectedAttribute')
    )
    if (localAttrList && localAttrList.length) {
      this.updatecustomSortedAttributes(localAttrList)
      this.updateSelectedAttribute(localSelecterAttrs)
    }
  },
  methods: {
    ...mapActions('sunriseSunset', [
      'updatecustomSortedAttributes',
      'updateSelectedAttribute'
    ]),
    collapseSidebar() {
      this.status = !this.status
    }
  }
}
</script>
<style lang="scss" src="./TrendingBuzz.scss" scoped />
